<template>
  <div class="goodslist">
    <div class="search_main">
      <van-sticky :offset-top="0">
        <van-search
          v-model="keywords"
          placeholder="请输入搜索关键词"
          show-action
          shape="round"
          @search="onSearch"
        >
          <template #left-icon>
            <div style="width:80px;display:flex;">
              <van-dropdown-menu class="selected">
                <van-dropdown-item v-model="tiaojiao" :options="tiaoJiaoOpt" />
              </van-dropdown-menu>
            </div>
          </template>
          <template #action>
            <div @click="onSearch" style="padding: 0 10px;">搜索</div>
          </template>
          <template #left>
            <div style="padding-right: 10px;line-height: 34px;" @click="OnClickLeft">
              <van-icon style="line-height: 34px;display: block;" color="#333" size="20" name="arrow-left" />
            </div>
          </template>
        </van-search>
        <van-cell center class="quanxuan">
          <template #title>
            <!-- <van-radio-group v-model="radio" @change="quanxuan2">
              <van-radio name="1">全选</van-radio>
            </van-radio-group> -->
          </template>
          <template #right-icon>
            <van-button size="mini" round @click="changeprice('all')" type="warning" style="margin-right: 10px;">全部商品改价</van-button>
            <van-button size="mini" round @click="quanxuan2">当前页选中</van-button>
          </template>
        </van-cell>
      </van-sticky>
      <div class="shaixuan">
        <van-grid :column-num="4" :border="false">
          <van-grid-item @click="sortList(0)">
            <template #text>
              <div :class="sort == 1 ? 'sort_list red' : 'sort_list'">新品</div>
            </template>
          </van-grid-item>
          <van-grid-item @click="sortList(1)">
            <template #text>
              <div class="sort_list">
                <span :class="sort == 2 || sort == 3 ? 'red' : ''">销量</span>
                <span class="sort_asc">
                  <van-icon :class="sort == 2 ? 'red' : ''" name="arrow-up" />
                  <van-icon :class="sort == 3 ? 'red' : ''" name="arrow-down" />
                </span>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item @click="sortList(2)">
            <template #text>
              <div class="sort_list">
                <span :class="sort == 4 || sort == 5 ? 'red' : ''">价格</span>
                <span class="sort_asc">
                  <van-icon :class="sort == 4 ? 'red' : ''" name="arrow-up" />
                  <van-icon :class="sort == 5 ? 'red' : ''" name="arrow-down" />
                </span>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item @click="changeprice('select')">
            <template #text>
              <div class="sort_list">选中改价</div>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <div class="man_list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="onLoad"
      >
        <van-checkbox-group v-model="result" ref="checkboxGroup">
          <van-cell-group
            v-for="(item, index) in list"
            :key="index"
            :border="false"
            @click="toggle(index)"
          >
            <van-cell center :border="false">
              <template #right-icon>
                <van-checkbox :name="item.id" ref="checkboxes" />
              </template>
              <template #title>
                <van-image
                  width="80"
                  height="80"
                  lazy-load
                  :src="item.thumb"
                  fit="cover"
                >
                  <van-tag
                    mark
                    :type="item.mid > 0 ? 'primary' : 'danger'"
                    size="mini"
                  >
                    {{item.mid > 0 ? "上架" : "下架"}}
                  </van-tag>
                </van-image>
              </template>
              <template #default>
                <div class="van-ellipsis">
                  {{item.title}}
                </div>
                <div class="list_content">
                  <div class="list_text">
                    <div class="list_text_attr van-ellipsis">{{item.shorttitle}}</div>
                    <div class="list_price">
                      <span class="price_pref">&yen;</span>
                      <span class="price_num">{{item.price}}</span>
                      <van-tag type="danger">最高可赚</van-tag>
                      <van-tag type="danger" plain>&yen;{{item.commission1_pay}}</van-tag>
                    </div>
                  </div>
                </div>
              </template>
            </van-cell>
            <van-cell center>
              <template #default>
                <van-grid direction="horizontal" :column-num="4" clickable>
                  <van-grid-item @click.stop="yulan(item.id)" text="预览" />
                  <van-grid-item @click.stop="goodsup(index)" text="下架" />
                  <van-grid-item @click.stop="gaijia(item.id)" text="改价" />
                  <van-grid-item @click.stop="fenxiang(item.id, item)" text="分享" />
                </van-grid>
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </van-list>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "Goodslist",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      keywords: "", // 搜索关键词
      desc: 1, // 排序
      sort: 1,
      result: [], // 已选数据
      list: [], // 数据列表
      loading: false,
      finished: false,
      checked: false,
      currOffset: 0,
      currSize: 20,
      info: {},
      xcx: 0,
      radio: '',
      tiaojiao: 0,
      tiaoJiaoOpt: [
        { text: '是否改价', value: 0 },
        { text: '已调价', value: 1 },
        { text: '未改价', value: 2 },
      ],
    }
  },
  mounted() {
    this.xcx = this.$route.query.xcx ? this.$route.query.xcx : 0;
    this.onLoad();
    this.getinfo();
  },
  activated() {
    this.result = [];
    localStorage.removeItem('upgid');
    console.log("ddddd");
  },
  methods: {
    // 获取商户信息
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 获取产品列表
    onLoad() {
      let that = null;
      that = this;
      that.loading = true;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/get_my_list",
          that.$qs.stringify({
            offset: that.currOffset,
            size: that.currSize,
            keywords: that.keywords,
            tiaojiao: that.tiaojiao,
            desc: that.desc
          })
        )
        .then(res => {
          console.log(res);
          that.loading = false;
          if (res.data.code == 100000) {
            if (res.data.data.length >= that.currSize) {
              that.finished = false;
              if (that.currOffset == 0) {
                that.list = res.data.data;
              }else {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }
              that.currOffset += that.currSize;
            }else {
              that.finished = true;
              if (that.currOffset == 0) {
                that.list = res.data.data;
              }else {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }
              that.currOffset += res.data.data.length;
            }
          }else {
            that.finished = true;
          }
        })
        .catch(err => {
          console.log(err);
          that.finished = false;
          that.finished = true;
        })
    },
    // 排序方法
    descChange(id) {
      this.desc = id;
      this.currOffset = 0;
      this.onLoad();
    },
    // 点击排序按钮
    sortList(num) {
      if (num == 0) {
        this.sort = 1;
      }else if(num == 1) {
        if (this.sort == 2) {
          this.sort = 3;
        }else{
          this.sort = 2;
        }
      }else if(num == 2) {
        if (this.sort == 4) {
          this.sort = 5
        }else {
          this.sort = 4
        }
      }
      this.desc = this.sort;
      this.currOffset = 0;
      this.onLoad();
      console.log(num, this.sort, 'ddd');
    },
    // 搜索
    onSearch() {
      console.log("search");
      this.currOffset = 0;
      this.onLoad();
    },
    // 上下架按钮
    goodsup(index) {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/goods/set_status",
          that.$qs.stringify({
            gid: that.list[index].id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "下架成功");
            that.list.splice(index, 1);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "下架失败");
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 返回上一页
    OnClickLeft() {
      this.$router.back();
    },
    // 商品预览
    yulan(id) {
      if (this.xcx == 0) {
        window.location.href = this.$store.state.dakaurl + "details?id=" + id + "&shareid=" + this.info.memberid + "&merchid=" + this.info.id;
      }else {
        window.wx.miniProgram.navigateTo({
          url: "/pages/goods/details?id=" + id
        })
      }
    },
    // 复制分享链接
    fenxiang(id, goods) {
      console.log(id, this.info, goods);
      let _that = null;
      _that = this;
      if (this.xcx == 0) {
        let uri = "";
        uri = this.$store.state.dakaurl + "details?id=" + id + "&shareid=" + this.info.memberid + "&merchid=" + this.info.id;
        
        _that.$copyText(uri).then(
          function(e) {
            _that.$toast("分享链接复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
      }else {
        _that.$toast({
          type: "type",
          message: '加载中...',
          forbidClick: true,
          duration: 0
        });
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/goodsposter/createmini",
            _that.$qs.stringify({
              "goods_id": id, // 商品ID：
              "user_id": _that.info.memberid, // 会员ID：
              "shop_id": _that.info.id, // 店铺ID：
              "shop_name": _that.info.merchname, // 店铺名称：
              "shop_logo": _that.info.logo, // 店铺LOGO：
              "goods_name": goods.title, // 商品名称：
              "goods_img": goods.thumb, // 商品收徒：
              "qr_url": "pages/goods/details", // 二维码链接：
              "appid": "wx6a6b5e6fbb588e66"
            })
          )
          .then(res => {
            console.log(res);
            _that.$toast.clear();
            if (res.data.code == 100000) {
              ImagePreview([res.data.data]);
            }else{
              _that.$toast(res.data.msg ? res.data.msg : "获取失败");
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 改价
    gaijia(id) {
      console.log(id);
      this.$router.push({
        name: "GoodsEdit",
        query: {
          id: id,
          t: new Date().getTime()
        }
      });
    },
    // 全局改价
    changeprice(changepricetype) {
      if(changepricetype == 'select'){
        if(this.result.length <= 0){
          this.$toast("请选择要改价的商品");
          return false;
        }
        localStorage.setItem("upgid", JSON.stringify(this.result));
      }
      localStorage.setItem("changepricetype", changepricetype);
      this.$router.push({
        name: "GoodsPriceEdit",
        query: {
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    },
    toggle(index) {
      console.log(index);
      this.$refs.checkboxes[index].toggle();
      // let id = 0;
      // id = parseInt(this.list[index].id);
      
      // // 改变复选框状态
      // if (this.result.indexOf(id) >= 0) {
      //   this.result.splice(this.result.indexOf(id), 1);
      // } else {
      //   this.result.push(id);
      // }
      console.log(this.result);
    },
    quanxuan2() {

      if (this.result.length < this.list.length) {
        this.radio = '1';
        this.$refs.checkboxGroup.toggleAll(true);
      }else {
        this.radio = '';
        this.$refs.checkboxGroup.toggleAll();
      }

      // console.log(this.radio);
      // if (this.radio == '') {
      //   this.radio = '1';
      // }

      // if (this.radio == '1') {
      //   this.radio = '';
      //   this.result = []
      // }else {
      //   this.radio = '1';
      //   this.result = []
      //   this.list.forEach(item => {
      //     this.result.push(item.id+'');
      //   })
      // }
    }
  },
}
</script>
<style lang="less">
.goodslist {
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  // &:last-child {
  //   padding-bottom: 50px;
  // }
  .search_main {
    overflow: hidden;
    margin-bottom: 10px;
    .van-search {
      .van-cell__value {
        flex: 1;
      }
    }
    .shaixuan {
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      overflow: hidden;
      background-color: #fff;
      .van-grid-item__text{
        font-size: 14px;
      }
      .van-grid-item__content {
        padding: 10px 8px;
      }
      .sort_list{
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        .sort_asc{
          display: inline-block;
          overflow: hidden;
          vertical-align: middle;
          height: 10px;
          line-height: 4px;
          width: 12px;
          .van-icon{
            font-size: 8px;
            line-height: 4px;
          }
        }
      }
    }
  }
  .selected {
    .van-dropdown-menu__bar {
      height: 24px;
      box-shadow: none;
      padding-right: 10px;
      background-color: #F7F8FA;
      .van-dropdown-menu__item {
        background-color: #F7F8FA;
        .van-dropdown-menu__title {
          font-size: 12px;
          color: #999;
        }
      }
    }
    .van-dropdown-item--down .van-cell {
      padding: 5px 16px;
      .van-cell__value .van-icon {
        color: #ee0a24;
      }
    }
  }
  .quanxuan {
    .van-cell__title {
      flex: 1;
      text-align: left;
    }
    .van-button {
      padding: 2px 10px;
    }
  }
  .man_list {
    overflow: hidden;
    padding-bottom: 50px;
    .van-cell-group {
      margin-bottom: 10px;
      .van-cell {
        overflow: hidden;
        &:last-of-type {
          padding-top: 0;
        }
        .van-cell__title {
          padding-right: 10px;
          overflow: hidden;
          min-width: 80px;
          .van-image {
            border-radius: 6px;
            overflow: hidden;
            display: block;
            .van-tag {
              position: absolute;
              left: 0px;
              top: 5px;
            }
          }
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          overflow: hidden;
          color: #333;
          .list_content {
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-items: center;
            .list_text {
              flex: 1;
              overflow: hidden;
              .list_text_attr {
                color: #999;
                line-height: 20px;
                font-size: 12px;
              }
              .list_price {
                font-size: 12px;
                .price_num {
                  font-size: 14px;
                  padding: 0 5px 0 1px;
                }
                .van-tag--danger {
                  border-radius: 2px 0 0 2px;
                }
                .van-tag--plain {
                  border-radius: 0 2px 2px 0;
                }
              }
            }
          }
          .van-grid-item__content {
            padding: 10px 8px;
          }
        }
      }
    }
    
    .van-submit-bar {
      bottom: 50px;
      z-index: 9;
      .van-submit-bar__bar {
        justify-content: space-between;
      }
    }
  }
  .red {
    color: #ff0000;
  }
}
</style>
